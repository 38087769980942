/* Custom table styling */
.custom-table {
  width: 100%;
  border-collapse: collapse;
  table-layout: auto; /* Column width adjusts according to content */
}

/* Table border */
.custom-table,
.custom-table th,
.custom-table td {
  border: 1px solid #ccc;
  padding: 8px;
  text-align: left;
  align-content: center;
}
.custom-table tr:nth-child(even) {
  background-color: #f9f9f9 !important; 
}

.custom-table tr:nth-child(odd) {
  background-color: #ffffff !important; 
}
.custom-table td {
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 200px;
  transition: max-width 0.3s ease;
}
.custom-table th {
  background-color: #333 !important;
  color: #fff !important;
  padding: 10px;
  font-weight: bold;
}
