@import "./vars.scss";
.form-box-mobile {
  margin-bottom: 10px;
  position: relative;
  label {
    display: block;
    font-size: 14px;
    color: #848383;
    font-weight: 1300;
  }
  .react-datepicker-wrapper {
    width: 100%;
  }
  p {
    background: #aeaeae;
    padding: 10px;
    width: 100%;
    display: block;
    user-select: none;
    color: black;
  }
  .search-icon-gstin {
    width: 20px;
    height: 20px;
    position: absolute;
    right: 15px;
    bottom: 11px;
  }
  .otp-box {
    margin: 20px 0;
    .otp-txt {
      color: #848383;
      font-size: 12px;
      margin-bottom: 10px;
      display: block;
    }
    .verify-otp-input-box {
      display: flex;
      justify-content: space-between;
      input {
        width: 60%;
      }
      button {
        width: 30%;
      }
    }
  }
  &.icon-box {
    position: relative;
    img {
      position: absolute;
      width: 20px;
      height: 20px;
      top: 43px;
      left: 10px;
      margin-right: 10px;
    }
    span {
      padding-left: 40px;
      height: 40px;
      line-height: 40px;
      border-radius: 4px;
      width: 100%;
      border: none;
      box-shadow: 0px 0px 5px #333333;
      display: inline-block;
      background: #ffffff;
    }
  }
  input,
  textarea {
    height: 40px;
    line-height: 22px;
    border-radius: 4px;
    width: 100%;
    border: none;
    padding: 5px 20px;
    background-color: #f5f5f5;
    border: 1px solid #007bff;
    &.active {
      box-shadow: 0px 0px 5px #333333;
    }
    &.white-back {
      background-color: #ffffff;
    }
    &.disable {
      background-color: #aeaeae;
      user-select: none;
    }
  }
  textarea {
    min-height: 80px;
  }
  .gender-box {
    display: flex;
    justify-content: space-between;
    height: 40px;
    line-height: 40px;
    width: 100%;
    border: none;
    margin-bottom: 10px;
    .gender {
      display: flex;
      align-items: center;
      box-shadow: 0px 0px 1px #333333;
      padding: 5px 10px;
      background-color: #ffffff;
      border-radius: 4px;
      &.active {
        box-shadow: 0px 0px 5px #333333;
      }
      img {
        width: 20px;
        height: 20px;
        margin-right: 10px;
      }
    }
  }
}
.form-box-mobile-date-picker {
  .react-datepicker-wrapper {
    width: 100%;
  }
  input,
  textarea {
    height: 40px;
    line-height: 40px;
    border-radius: 4px;
    width: 100%;
    border: none;
    padding: 5px 20px;
    background-color: #f5f5f5;
    border: 1px solid #007bff;
    &.active {
      box-shadow: 0px 0px 5px #333333;
    }
    &.white-back {
      background-color: #ffffff;
    }
    &.disable {
      background-color: #aeaeae;
      user-select: none;
    }
  }
}
h4.modal-heading {
  font-weight: 500 !important;
  font-size: 18px;
}
.search-gst-number .modal-body {
  padding: 20px;
}
.prev-next-btn-box {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}
.bottom-fixed-btn-box {
  width: 100%;
}
.terms-box {
  display: flex;
  img {
    width: 18px;
    height: 18px;
    margin-right: 15px;
    margin-top: 4px;
  }
  span {
    font-size: 12px;
    a {
      text-decoration: underline;
    }
  }
}
.select-gst-radio {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
  img {
    margin-right: 10px;
    width: 20px;
    height: 20px;
  }
}
