@import "./form.scss";
@import "./buttons.scss";
@import "./media.scss";

.form-editable-section {
  width: 100%;
  .form-box-account {
    max-width: 100% !important;
    border-radius: 8px;
    box-shadow: 0 1px 4px $ShadowColor;
    background-color: $White;
    padding: 0;
    .mgbt-xs-15 {
      margin-bottom: 15px !important;
    }
    .account-detail {
      padding: 20px;
      .menu {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 15px;
        .icon-box {
          img {
            width: 14px;
            height: 14px;
          }
        }
        .heading-box {
          align-items: center;
          img {
            width: 20px;
            height: 20px;
            margin-right: 15px;
          }
        }
      }
      &.flex {
        .col-3 {
          width: 30%;
        }
        .pricing-tble-grid {
          display: block;
          margin: 50px 0;
        }
        .selectPackageTYpe {
          .col-9 {
            width: 100%;
          }
        }
      }
      .form-group {
        display: flex;
        margin-bottom: 15px;
        .control-label {
          padding-top: 4px;
          margin-bottom: 0;
          flex: 1;
        }
        .controls {
          width: 75%;
          padding: 0 15px;
          ion-icon {
            font-size: 24px !important;
            vertical-align: middle;
          }
          .uploadBox {
            border: 1px solid $Black;
            height: 40px;
            padding: 4px;
            width: 100%;
            text-align: center;
            border-radius: 8px;
            user-select: none;
            position: relative;
          }
          .uploadViewBox {
            background: $Primary;
            border-radius: 10px;
            text-align: center;
            height: 40px;
            line-height: 40px;
            user-select: none;
            color: $White;
            position: relative;
          }
        }
        input,
        select {
          width: 100%;
          border: 1px solid $BorderColor;
          padding: 6px 6px 7px;
        }
        textarea {
          resize: none;
          min-height: 60px;
          width: 100%;
        }
      }
    }
  }
}

.error {
  color: $Error !important;
  font-size: 12px;
  box-shadow: none !important;
  background: transparent !important;
  padding: 0 !important;
}

.minimalistic-table {
  width: 100%;
  border-collapse: collapse;
  font-family: Arial, sans-serif;
  table-layout: auto;
}

.minimalistic-table th,
.minimalistic-table td {
  text-align: left;
  border: 1px solid #ddd;
}

.minimalistic-table thead th {
  background-color: #f2f2f2; 

}

.minimalistic-table tbody td {
  white-space: nowrap;
}

.minimalistic-table tbody tr:nth-child(even) {
  background-color: #f9f9f9; /* Light gray for even rows */
}

.minimalistic-table tbody tr:nth-child(odd) {
  background-color: #fff; /* White for odd rows */
}



.profile-page {
  transition: filter 0.3s ease;
}

.blurred {
  filter: blur(5px);
  pointer-events: none; /* Prevent interactions with blurred content */
}

.loading-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(
    255,
    255,
    255,
    0.5
  ); /* Optional, to dim the background */
  z-index: 1000;
}

.loading-spinner {
  font-size: 1.5rem;
}

/* From Uiverse.io by uxRakhal */
.typewriter {
  --blue: #5cbbff;
  --blue-dark: #162d72;
  --key: #fff;
  --paper: #eef0fd;
  --text: #00000049;
  --tool: #ffbb00;
  --duration: 3s;
  position: relative;
  -webkit-animation: bounce05 var(--duration) linear infinite;
  animation: bounce05 var(--duration) linear infinite;
}

.typewriter .slide {
  width: 92px;
  height: 20px;
  border-radius: 3px;
  margin-left: 14px;
  transform: translateX(14px);
  background: linear-gradient(var(--blue), var(--blue-dark));
  -webkit-animation: slide05 var(--duration) ease infinite;
  animation: slide05 var(--duration) ease infinite;
}

.typewriter .slide:before,
.typewriter .slide:after,
.typewriter .slide i:before {
  content: "";
  position: absolute;
  background: var(--tool);
}

.typewriter .slide:before {
  width: 2px;
  height: 8px;
  top: 6px;
  left: 100%;
}

.typewriter .slide:after {
  left: 94px;
  top: 3px;
  height: 14px;
  width: 6px;
  border-radius: 3px;
}

.typewriter .slide i {
  display: block;
  position: absolute;
  right: 100%;
  width: 6px;
  height: 4px;
  top: 4px;
  background: var(--tool);
}

.typewriter .slide i:before {
  right: 100%;
  top: -2px;
  width: 4px;
  border-radius: 2px;
  height: 14px;
}

.typewriter .paper {
  position: absolute;
  left: 24px;
  top: -26px;
  width: 40px;
  height: 46px;
  border-radius: 5px;
  background: var(--paper);
  transform: translateY(46px);
  -webkit-animation: paper05 var(--duration) linear infinite;
  animation: paper05 var(--duration) linear infinite;
}

.typewriter .paper:before {
  content: "";
  position: absolute;
  left: 6px;
  right: 6px;
  top: 7px;
  border-radius: 2px;
  height: 4px;
  transform: scaleY(0.8);
  background: var(--text);
  box-shadow: 0 12px 0 var(--text), 0 24px 0 var(--text), 0 36px 0 var(--text);
}

.typewriter .keyboard {
  width: 120px;
  height: 56px;
  margin-top: -10px;
  z-index: 1;
  position: relative;
}

.typewriter .keyboard:before,
.typewriter .keyboard:after {
  content: "";
  position: absolute;
}

.typewriter .keyboard:before {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: 7px;
  background: linear-gradient(135deg, var(--blue), var(--blue-dark));
  transform: perspective(10px) rotateX(2deg);
  transform-origin: 50% 100%;
}

.typewriter .keyboard:after {
  left: 2px;
  top: 25px;
  width: 11px;
  height: 4px;
  border-radius: 2px;
  box-shadow: 15px 0 0 var(--key), 30px 0 0 var(--key), 45px 0 0 var(--key),
    60px 0 0 var(--key), 75px 0 0 var(--key), 90px 0 0 var(--key),
    22px 10px 0 var(--key), 37px 10px 0 var(--key), 52px 10px 0 var(--key),
    60px 10px 0 var(--key), 68px 10px 0 var(--key), 83px 10px 0 var(--key);
  -webkit-animation: keyboard05 var(--duration) linear infinite;
  animation: keyboard05 var(--duration) linear infinite;
}

@keyframes bounce05 {
  85%,
  92%,
  100% {
    transform: translateY(0);
  }

  89% {
    transform: translateY(-4px);
  }

  95% {
    transform: translateY(2px);
  }
}

@keyframes slide05 {
  5% {
    transform: translateX(14px);
  }

  15%,
  30% {
    transform: translateX(6px);
  }

  40%,
  55% {
    transform: translateX(0);
  }

  65%,
  70% {
    transform: translateX(-4px);
  }

  80%,
  89% {
    transform: translateX(-12px);
  }

  100% {
    transform: translateX(14px);
  }
}

@keyframes paper05 {
  5% {
    transform: translateY(46px);
  }

  20%,
  30% {
    transform: translateY(34px);
  }

  40%,
  55% {
    transform: translateY(22px);
  }

  65%,
  70% {
    transform: translateY(10px);
  }

  80%,
  85% {
    transform: translateY(0);
  }

  92%,
  100% {
    transform: translateY(46px);
  }
}

@keyframes keyboard05 {
  5%,
  12%,
  21%,
  30%,
  39%,
  48%,
  57%,
  66%,
  75%,
  84% {
    box-shadow: 15px 0 0 var(--key), 30px 0 0 var(--key), 45px 0 0 var(--key),
      60px 0 0 var(--key), 75px 0 0 var(--key), 90px 0 0 var(--key),
      22px 10px 0 var(--key), 37px 10px 0 var(--key), 52px 10px 0 var(--key),
      60px 10px 0 var(--key), 68px 10px 0 var(--key), 83px 10px 0 var(--key);
  }

  9% {
    box-shadow: 15px 2px 0 var(--key), 30px 0 0 var(--key), 45px 0 0 var(--key),
      60px 0 0 var(--key), 75px 0 0 var(--key), 90px 0 0 var(--key),
      22px 10px 0 var(--key), 37px 10px 0 var(--key), 52px 10px 0 var(--key),
      60px 10px 0 var(--key), 68px 10px 0 var(--key), 83px 10px 0 var(--key);
  }

  18% {
    box-shadow: 15px 0 0 var(--key), 30px 0 0 var(--key), 45px 0 0 var(--key),
      60px 2px 0 var(--key), 75px 0 0 var(--key), 90px 0 0 var(--key),
      22px 10px 0 var(--key), 37px 10px 0 var(--key), 52px 10px 0 var(--key),
      60px 10px 0 var(--key), 68px 10px 0 var(--key), 83px 10px 0 var(--key);
  }

  27% {
    box-shadow: 15px 0 0 var(--key), 30px 0 0 var(--key), 45px 0 0 var(--key),
      60px 0 0 var(--key), 75px 0 0 var(--key), 90px 0 0 var(--key),
      22px 12px 0 var(--key), 37px 10px 0 var(--key), 52px 10px 0 var(--key),
      60px 10px 0 var(--key), 68px 10px 0 var(--key), 83px 10px 0 var(--key);
  }

  36% {
    box-shadow: 15px 0 0 var(--key), 30px 0 0 var(--key), 45px 0 0 var(--key),
      60px 0 0 var(--key), 75px 0 0 var(--key), 90px 0 0 var(--key),
      22px 10px 0 var(--key), 37px 10px 0 var(--key), 52px 12px 0 var(--key),
      60px 12px 0 var(--key), 68px 12px 0 var(--key), 83px 10px 0 var(--key);
  }

  45% {
    box-shadow: 15px 0 0 var(--key), 30px 0 0 var(--key), 45px 0 0 var(--key),
      60px 0 0 var(--key), 75px 0 0 var(--key), 90px 2px 0 var(--key),
      22px 10px 0 var(--key), 37px 10px 0 var(--key), 52px 10px 0 var(--key),
      60px 10px 0 var(--key), 68px 10px 0 var(--key), 83px 10px 0 var(--key);
  }

  54% {
    box-shadow: 15px 0 0 var(--key), 30px 2px 0 var(--key), 45px 0 0 var(--key),
      60px 0 0 var(--key), 75px 0 0 var(--key), 90px 0 0 var(--key),
      22px 10px 0 var(--key), 37px 10px 0 var(--key), 52px 10px 0 var(--key),
      60px 10px 0 var(--key), 68px 10px 0 var(--key), 83px 10px 0 var(--key);
  }

  63% {
    box-shadow: 15px 0 0 var(--key), 30px 0 0 var(--key), 45px 0 0 var(--key),
      60px 0 0 var(--key), 75px 0 0 var(--key), 90px 0 0 var(--key),
      22px 10px 0 var(--key), 37px 10px 0 var(--key), 52px 10px 0 var(--key),
      60px 10px 0 var(--key), 68px 10px 0 var(--key), 83px 12px 0 var(--key);
  }

  72% {
    box-shadow: 15px 0 0 var(--key), 30px 0 0 var(--key), 45px 2px 0 var(--key),
      60px 0 0 var(--key), 75px 0 0 var(--key), 90px 0 0 var(--key),
      22px 10px 0 var(--key), 37px 10px 0 var(--key), 52px 10px 0 var(--key),
      60px 10px 0 var(--key), 68px 10px 0 var(--key), 83px 10px 0 var(--key);
  }

  81% {
    box-shadow: 15px 0 0 var(--key), 30px 0 0 var(--key), 45px 0 0 var(--key),
      60px 0 0 var(--key), 75px 0 0 var(--key), 90px 0 0 var(--key),
      22px 10px 0 var(--key), 37px 12px 0 var(--key), 52px 10px 0 var(--key),
      60px 10px 0 var(--key), 68px 10px 0 var(--key), 83px 10px 0 var(--key);
  }
}
.loader-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(143, 143, 143, 0.5);
  z-index: 1000;
  backdrop-filter: blur(2px);
}

.loader {
  border: 4px solid rgba(0, 0, 0, 0.1);
  border-left-color: transparent;
  border-radius: 50%;
  width: 36px;
  height: 36px;
  animation: spin89345 1s linear infinite;
}

@keyframes spin89345 {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
@keyframes blink {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.blinking-text {
  animation: blink 2s infinite;
}
