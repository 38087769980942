h2{font-size: 3rem;}
h3.smallHeading{
    font-size:1.6rem;
}
.title{
    font-size: 17px;
    font-weight: 600;
}

.disable{
    background-color: #f2f2f2 !important;
    pointer-events: none;
    font-weight: 100 !important;
    color:#ccc !important
}

.actions img{
    width:30px;
    height:auto;
    margin:0 5px;
}
.remarksHeading{
    display: inline-flex;
    gap: 15px;
    align-items: center;
    font-weight: bold;
    
}
.remarksHeading span{
    font-size: 17px;
}

.remarViewAll{
    color:#0096FF !important;
    text-align: left;
}
.remarViewAll span{
    color:#0096FF !important;
    cursor: pointer;
}
.remarkRow:not(:last-child){
    border-bottom:1px solid #ccc;
    margin-bottom:15px;
}