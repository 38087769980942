.close {
  color: #aaaaaa;
  float: right;
  font-size: 28px;
  font-weight: bold;
}

.close:hover,
.close:focus {
  color: #000;
  text-decoration: none;
  cursor: pointer;
}

.button {
  margin-left: 25px;
  background-color: #1d58ab;
  color: #ffffff !important;
  transition: 0.1s all linear;
  max-width: 180px;
  line-height: 30px;
  height: 34px;
  /* display: block; */
  font-family: "Poppins", sans-serif;
  font-size: 14px !important;
  font-weight: 400;
  border-radius: 6px;
  border: 2px solid #1d58ab;
  text-align: center;
  text-decoration: none;
  padding: 0 20px !important;
  min-width: 120px;
}

.text {
  font-size: 18px;
  font-weight: bold;
  text-align: center;
}
.settled_history {
  width: 22px;
}
.table {
  font-family: arial, sans-serif;
  border-collapse: collapse;
  width: 100%;
}

.td {
  border: 2px solid #dddddd;
  text-align: left;
  padding: 8px;
}
.th {
  border: 1px solid #dddddd;
  text-align: left;
  padding: 8px;
}
