$Yellow: #ee852a;
$LightGray: #d4d4d4;
$AshGray: #848383;
$Black: #000;
$Gray: #333;
$Dark: #181818;
$White: #ffffff;
$BorderColor: #dddddd;
$Error: #ea4335;
$Primary: #1d58ab;
$Success: #007600;
$Info: #00a6ff;
$Cream: #fbf7e6;
$Warning: #ffbd46;
$BackGray: #f4f4f4;
$ShadowColor: #cccccc;
$FbColor: #4867aa;
$GoogleColor: #d34d41;
$PrimaryLight: #1d58ab82;
$FooterBackground: #f6fbff;
$BackgroundColor: #f0f3f7;
$TableBackground: #d0d0ce;
$DarkShadow: 2px 2px 20px 5px rgba(0, 0, 0, 0.1);
