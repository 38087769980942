@import './vars.scss';
.btn {
    cursor: pointer;
    display: block;
    font-family: "Poppins", sans-serif;
    font-size: 12px !important;
    font-weight: 400;
    height: 40px;
    border-radius: 6px;
    text-transform: uppercase;
    border: 2px solid $Primary;
    line-height: 36px;
    text-align: center;
    text-decoration: none;
    padding: 0 20px !important;
    min-width: 120px;
    user-select: none;
    ion-icon {
        vertical-align: middle;
        margin-right: 8px;
    }
    @media(max-width: 768px) {
        font-size: 10px !important;
    }
    &.btn-outline {
        background-color: $White;
        color: $Primary !important;
        transition: 0.1s all linear;
        &:hover {
            background-color: $Primary;
            color: $White !important;
        }
    }
    &.btn-small {
        min-width: 100px;
        line-height: 30px;
        height: 34px;
    }
    &.btn-theme {
        background-color: $Primary;
        color: $White !important;
        transition: 0.1s all linear;
        &:hover {
            background-color: $White;
            color: $Primary !important;
        }
        @media (max-width: 768px) {
            line-height: 30px;
            height: 34px;
        }
    }
    &.max-width-btn {
        max-width: 150px;
    }
    &.btn-error {
        background-color: $Error;
        color: $White !important;
        transition: 0.1s all linear;
    }
    &.btn-gray {
        background-color: $AshGray;
        color: $White !important;
        transition: 0.1s all linear;
        border: 1px solid $AshGray;
    }
    &.btn-black {
        &:hover {
            background-color: $White;
            color: $Dark !important;
            border: 2px solid $Dark;
        }
    }
    &.btn-box {
        max-width: 160px;
        width: 100%;
    }
    &.btn-half {
        width: 50%;
    }
    &.btn-big {
        width: 200px;
        height: 50px !important;
        line-height: 48px !important;
        border-radius: 10px !important;
        font-size: 16px !important;
    }
    &:disabled {
        cursor: not-allowed;
    }
}