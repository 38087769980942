* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

/* div.scrollmenu {
    background-color: #333;
    overflow: auto;
    white-space: nowrap;
  }
  
  div.scrollmenu {
    display: inline-block;
    color: white;
    text-align: center;
    padding: 14px;
    text-decoration: none;
  }
  
  div.scrollmenu {
    background-color: #777;
  } */

.head_line {
  color: aliceblue;
  font-weight: bold;
  font-size: 10px;
  margin-top: 100px;
}

.mainDiv {
  padding-top: 6%;
  height: 100%;
  width: 100%;
}
.invoice {
  margin-left: 8%;
  margin-top: 2%;
  width: 30%;
  height: 38%;
}
.invoiceFont {
  font-size: 80px;
}
.invoiceNum {
  width: 40%;
  height: 40%;
  float: left;
  margin-left: 4%;
  margin-top: -9%;
}
.invoiceDate {
  font-size: 18px;
  color: #696969;
}
.num {
  margin-top: -4%;
  font-weight: bold;
  font-size: 16px;
}
.Business {
  width: 100%;
  height: 40%;
}
.Buyer {
  width: 30%;
  height: 100%;
  float: left;
  margin-left: 9%;
}
.supplier {
  margin-top: 3%;
}
.buyerHeading {
  color: #696969;
  font-weight: bold;
}
.buyerfont {
  font-weight: bold;
  font-size: 18px;
}
.margin {
  margin-top: -2%;
}
.padding {
  padding-top: 4%;
  padding: 7px 0;
  font-size: 16px;
  margin: 0 25px;
  text-decoration: none;
  color: #181818;
  white-space: nowrap;
  transition: 0.3s all ease-in-out;
}
.totalAmount {
  width: 100%;
  margin-top: 2%;
  /* background-color:red; */
  height: 30%;
  margin-bottom: 10%;
}
.amountFont {
  margin-left: 7%;
  font-size: 29px;
  font-weight: bold;
}
.line {
  height: 1px;
  background: grey;
}
.subTotal {
  margin-left: 80%;
  width: 20%;
  height: 40%;
  margin-top: -5%;
}
.discount {
  font-weight: bold;
  font-size: 16px;
}
.mobileShow {
  display: block;
  width: 10%;
  height: 5%;
  position: fixed;
  bottom: 2%;
  left: 40%;
  margin: 0 0 0 -20%;
  background-color: #1d58ab;
  color: #ffffff !important;
  transition: 0.1s all linear;
  border-radius: 5px;
}
.reject {
  display: block;
  width: 10%;
  height: 5%;
  position: fixed;
  bottom: 2%;
  left: 60%;
  margin: 0 0 0 -20%;
  background-color: #1d58ab;
  color: #ffffff !important;
  transition: 0.1s all linear;
  border-radius: 5px;
}

.back {
  display: block;
  width: 10%;
  height: 5%;
  position: fixed;
  bottom: 2%;
  left: 80%;
  margin: 0 0 0 -20%;
  background-color: #1d58ab;
  color: #ffffff !important;
  transition: 0.1s all linear;
  border-radius: 5px;
}

.button {
  cursor: pointer;
  /* display: block; */
  font-family: "Poppins", sans-serif;
  font-size: 12px !important;
  font-weight: 400;
  height: 40px;
  border-radius: 6px;
  text-transform: uppercase;
  border: 2px solid #1d58ab;
  line-height: 36px;
  text-align: center;
  text-decoration: none;
  padding: 0 20px !important;
  min-width: 120px;
  -webkit-user-select: none;
  user-select: none;
  background-color: #1d58ab;
  color: #ffffff !important;
  transition: 0.1s all linear;
}

.grid-container {
  width: 50%;
  margin-left: 8%;
  display: grid;
  grid-template-columns: auto auto;
  /* background-color: #2196F3; */
  /* padding: 10px; */
}

.grid-item {
  background-color: rgba(255, 255, 255, 0.8);
  /* border: 1px solid rgba(0, 0, 0, 0.8); */
  padding: 20px;
  /* font-size: 30px; */
  text-align: left;
}

.resend-action-button {
  cursor: pointer;
  /* display: block; */
  width: 33px;
  height: 33px;
  margin-right: 10px;
  padding-top: 0px;
}
.pdf-action-button {
  cursor: pointer;
  /* display: block; */
  width: 30px;
  height: 26px;
  margin-right: 10px;
  padding-top: 0px;
}
.action-icon-display {
  display: flex;
}
.p-text {
  margin-top: 15px;
}
.button-text {
  padding-top: 7px;
  text-align: center;
}
.tcp-download-btn {
  position: absolute;
  right: 200px;
}

.download-tag {
  display: flex;
  position: relative;
}
.tcp-download-icon {
  height: 20px;
  display: inline-block !important;
  padding-top: 6px;
}
.selectBusiness {
  width: 50% !important;
  padding: 12px;
  display: flex;
}
.selectOption {
  padding-left: 10px;
  width: 250px;
}
.select-label {
  padding: 6px;
  font-size: 14px;
}

.add-business-input {
  height: 15px !important;
  /* marsgin-left: 0px !important; */
  width: 14px !important;
}

.add-business {
  display: inline !important;
  padding-left: 10px;
}

.label-gstin {
  color: black !important;
  margin-top: 10px;
}

.renew-button {
  margin-left: auto;
  margin-top: 10px;
  background-color: #1d58ab;
  color: #ffffff !important;
  transition: 0.1s all linear;
  max-width: 150px;
  line-height: 30px;
  height: 34px;
  cursor: pointer;
  display: block;
  font-family: "Poppins", sans-serif;
  font-size: 12px !important;
  font-weight: 400;
  height: 40px;
  border-radius: 6px;
  text-transform: uppercase;
  border: 2px solid #1d58ab;
  line-height: 36px;
  text-align: center;
  text-decoration: none;
  padding: 0 20px !important;
  min-width: 120px;
  -webkit-user-select: none;
  user-select: none;
}

.pay-amount-information {
  font-size: 10px;
  line-height: 1.3;
}
.tooltip {
  position: relative;
  display: inline-block;
}

.tooltip .tooltiptext {
  visibility: hidden;
  width: -webkit-fill-available;
  background-color: #555;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  z-index: 1;
  bottom: 100%;
  left: 50%;
  margin-left: -60px;
  opacity: 0;
  transition: opacity 0.3s;
  height: 45px;
  line-height: 1;
}

.tooltip .tooltiptext::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: #555 transparent transparent transparent;
}

.tooltip:hover .tooltiptext {
  visibility: visible;
  opacity: 1;
}

#customers {
  font-family: Arial, Helvetica, sans-serif;
  border-collapse: collapse;
  width: 100%;
  overflow-y: scroll;
  height: 400px;
}

#customers td,
#customers th {
  border: 1px solid #ddd;
  padding: 8px;
}

#customers tr:nth-child(even) {
  background-color: #f2f2f2;
}

#customers tr:hover {
  background-color: #ddd;
}

#customers tbody {
  text-align: center;
}

#customers th {
  padding-top: 12px;
  padding-bottom: 12px;
  text-align: left;
  background-color: #d0d0ce;
  text-align: center;
}

.select-tcp {
  margin-left: 20px;
  height: 34px;
  max-width: min-content;
  margin-top: 10px;
}
.totalOfBcm {
  margin-left: 16px;
  font-size: 18px;
}

.formCirDiv {
  border-radius: 5px;
  background-color: #f2f2f2;
  padding-left: 25%;
}
.inputSubmit {
  width: 70%;
  background-color: #1d58ab;
  color: #ffffff !important;
  transition: 0.1s all linear;
  padding: 14px 20px;
  margin: 8px 0;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}
.inputText {
  width: 70%;
  padding: 12px 20px;
  margin: 8px 0;
  display: inline-block;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
}
.form-control {
  font-family: system-ui, sans-serif;
  font-size: 2rem;
  font-weight: bold;
  line-height: 1.1;
  display: grid;
  grid-template-columns: 1em auto;
  gap: 0.5em;
}

.txn-date-input {
  border: 1px solid #c4c4c4;
  border-radius: 5px;
  background-color: #fff;
  padding: 5px 5px;
  box-shadow: inset 0 3px 6px rgba(0, 0, 0, 0.1);
  width: 190px;
}

.varLink {
  cursor: pointer;
}
.varLink:hover {
  color: rgb(57, 57, 187);
}
.varLinkError {
  cursor: pointer;
  color: red;
}
.email-div {
  float: left;
  text-align: center;
}

.sms-div {
  float: left;
  margin-left: 35px;
  text-align: center;
}
.icon-icon {
  font-size: 24px !important;
}

/*generate otp css */
.otp-div {
  margin-left: 20px;
}
/* togle button */
.toggle-div {
  float: right;
  margin-right: 38%;
}
.toggle {
  position: relative;
  display: inline-block;
  width: 62px;
  height: 30px;
  background-color: rgb(175, 170, 170);
  border-radius: 30px;
  border: 2px solid gray;
}

/* After slide changes */
.toggle:after {
  content: "";
  position: absolute;
  width: 22px;
  height: 22px;
  border-radius: 50%;
  background-color: rgb(248, 244, 244);
  top: 2px;
  left: 2px;
  transition: all 0.5s;
}

/* Checkbox checked effect */
.checkbox:checked + .toggle::after {
  left: 34px;
}

/* Checkbox checked toggle label bg color */
.checkbox:checked + .toggle {
  background-color: rgb(118, 127, 211);
}

/* Checkbox vanished */
.checkbox {
  display: none;
}

.otp-input-box {
  width: 20px !important;
  margin: 8px;
}
.flex-otp {
  display: flex;
}
.otp-td-width {
  width: 24%;
}
.toggle-td-width {
  width: 30%;
}

.set-admin-button {
  margin-top: 15px;
}
.set-limit {
  width: 40%;
  padding: 12px 20px;
  margin: 8px 0;
  display: inline-block;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
}
.set-limit:hover {
  border: 1px solid rgb(119, 119, 192);
}
.set-limit-button {
  margin-left: 2%;
  min-width: 100px !important;
  display: initial !important;
  padding: 0 10px !important;
}
.set-limit-div {
  margin-top: -6px;
}
.set-limit-text {
  padding-top: 10px;
}
.set-limit-rupee-symbol {
  position: absolute;
  padding-top: 1%;
  padding-left: 7px;
  font-size: 15px;
}
.set-limit-rupee-symbol-admin {
  position: absolute;
  padding-top: 2.6%;
  padding-left: 8px;
  font-size: 18px;
}
.set-limit-admin {
  width: 50% !important;
}
.set-limit-button-admin {
  position: absolute;
  left: 56%;
  top: 10%;
}

.ticketForm {
  position: fixed;
  bottom: 2.2%;
  right: 1.2%;
  display: block;
  /* width: 4%;
            height: 60px; */
  width: 4.7%;
  height: 68px;
  cursor: pointer;
}

.ticketFormText {
  position: fixed;
  bottom: 4.2%;
  right: 6%;
  color: black;
  background-color: #f7ed66;
  border-radius: 34px;
  padding: 6px;
  font-size: 13px;
  display: block;
  font-weight: bold;
}

.ticketForm:hover {
  width: 4.7%;
  height: 68px;
}

.serviceCharegesDiv {
  display: flex;
  width: 90%;
  margin-left: 5%;
}
.serviecesChragesFieldsDiv {
  margin: 7px;
  width: 40%;
  margin-left: 7%;
}
.remarksDiv {
  background-color: white;
  padding: 4px;
  border-radius: 7px;
  border: 1px outset;
  position: relative;
}
.viewAllRemark {
  font-size: 9px;
  color: blue;
  position: absolute;
  display: flex;
  right: 11px;
  bottom: -8px;
}
.prefields {
  background-color: #d8d1be !important;
}
.submitServiceButton {
  margin-left: 12%;
  margin-top: 30px;
  margin-bottom: 7%;
}
.addRemark {
  position: absolute;
  top: 5px;
  cursor: pointer;
}
.addRemarkIcon {
  width: 18px;
  cursor: pointer;
}

.addRemarkText {
  margin-left: 25px;
  font-size: 11px;
  background-color: rgb(146, 167, 180);
  color: white;
  font-weight: bold;
  padding: 5px;
  border-radius: 15px;
  display: none;
}
.addRemark:hover + .addRemarkText {
  display: inline;
}

.viewRemarkIcon {
  width: 20px;
  cursor: pointer;
}
.viewIconText {
  display: inline;
}
.viewRemarkIcon:hover + .viewIconText {
  display: inline;
}

.textArea {
  width: 100%;
  height: 125px;
  padding: 5px;
  line-height: 18px;
  font-size: 14px;
  font-family: arial, sans-serif;
  border-radius: 10px;
  margin-top: 10px;
  color: #5f6368;
  font-weight: bold;
}

.addRemarkButton {
  display: flex;
  padding: 10px;
  margin-top: 10px;
}
.cancelButton {
  margin-left: 20px;
}
.uploadPaymentFile {
  background-color: white;
  height: 42px;
  padding: 11px;
  width: 100%;
  border-radius: 5px;
  color: blue;
}

.formControl {
  display: block;
  width: 100%;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  appearance: none;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.textArea {
  height: 100px !important;
  min-height: auto !important;
}

.serviceCharegesDiv {
  display: flex;
  width: 80%;
  margin-left: 10%;
}
.serviceCharegesDivInnerDiv {
  width: 42%;
  margin-left: 6%;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 0.25rem;
  box-shadow: 0 0.5rem 1rem rgb(0 0 0 / 15%) !important;
}
.supplierExpiryWarnningDiv {
  text-align: center;
  padding-top: 10px;
  font-size: 16px;
  font-weight: bold;
}
.innerCard-agreement {
  /* justify-content: left; */
  text-align: justify;

  /* width: 45%; */
}
.agreementBox {
  display: inline-flex;
  margin-left: 40%;
}
