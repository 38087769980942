.form-box-mobile {
  margin-bottom: 10px;
  position: relative;
}

.form-box-mobile label {
  display: block;
  font-size: 14px;
  color: #aeaeae; /* Replace $AshGray with actual color value */
  font-weight: bold;
}

.form-box-mobile .react-datepicker-wrapper {
  width: 100%;
}

.form-box-mobile p {
  background: #bbbbbb; /* Replace $AshGray with actual color value */
  padding: 10px;
  width: 100%;
  display: block;
  user-select: none;
}

.form-box-mobile .search-icon {
  width: 20px;
  height: 20px;
  position: absolute;
  right: 15px;
  bottom: 11px;
}

.form-box-mobile .otp-box {
  margin: 20px 0;
}

.form-box-mobile .otp-box .otp-txt {
  color: #bbbbbb; /* Replace $AshGray with actual color value */
  font-size: 12px;
  margin-bottom: 10px;
  display: block;
}

.form-box-mobile .otp-box .verify-otp-input-box {
  display: flex;
  justify-content: space-between;
}

.form-box-mobile .otp-box .verify-otp-input-box input {
  width: 60%;
}

.form-box-mobile .otp-box .verify-otp-input-box button {
  width: 30%;
}

.form-box-mobile.icon-box {
  position: relative;
}

.form-box-mobile.icon-box img {
  position: absolute;
  width: 20px;
  height: 20px;
  top: 43px;
  left: 10px;
  margin-right: 10px;
}

.form-box-mobile.icon-box span {
  padding-left: 40px;
  height: 40px;
  line-height: 40px;
  border-radius: 4px;
  width: 100%;
  border: none;
  box-shadow: 0px 0px 5px #000; /* Replace $Dark with actual color value */
  display: inline-block;
  background: #fff; /* Replace $White with actual color value */
}

.form-box-mobile input,
.form-box-mobile textarea {
  height: 40px;
  line-height: 40px;
  border-radius: 4px;
  width: 100%;
  border: none;
  padding: 5px 20px;
  background-color: #f0f0f0; /* Replace $BackgroundColor with actual color value */
  border: 1px solid #007bff; /* Replace $Primary with actual color value */
}

.form-box-mobile input.active,
.form-box-mobile textarea.active {
  box-shadow: 0px 0px 5px #000; /* Replace $Dark with actual color value */
}

.form-box-mobile input.white-back,
.form-box-mobile textarea.white-back {
  background-color: #fff; /* Replace $White with actual color value */
}

.form-box-mobile input.disable,
.form-box-mobile textarea.disable {
  background-color: #bbbbbb; /* Replace $AshGray with actual color value */
  user-select: none;
}

.form-box-mobile textarea {
  min-height: 80px;
}

.form-box-mobile .gender-box {
  display: flex;
  justify-content: space-between;
  height: 40px;
  line-height: 40px;
  width: 100%;
  border: none;
  margin-bottom: 10px;
}

.form-box-mobile .gender-box .gender {
  display: flex;
  align-items: center;
  box-shadow: 0px 0px 1px #000; /* Replace $Dark with actual color value */
  padding: 5px 10px;
  background-color: #fff; /* Replace $White with actual color value */
  border-radius: 4px;
}

.form-box-mobile .gender-box .gender.active {
  box-shadow: 0px 0px 5px #000; /* Replace $Dark with actual color value */
}

.form-box-mobile .gender-box .gender img {
  width: 20px;
  height: 20px;
  margin-right: 10px;
}

.input {
  height: 40px;
  line-height: 40px;
  border-radius: 4px;
  width: 100%;
  border: 1px solid #ccc;
  padding: 5px 20px;
  background-color: #f0f0f0;
}

.label.error,
.input.error {
  border-color: red;
  color: red;
}

.error-message {
  color: red;
  font-size: 12px;
  margin-top: 5px;
}

.flex {
  display: flex;
}

.mt20 {
  margin-top: 20px;
}

.mlauto {
  margin-left: auto;
}

.btn-theme {
  background-color: #007bff;
  color: white;
  padding: 7px 16px;
  border: none;
  border-radius: 16px;
  cursor: pointer;
  transition: background-color 0.3s ease, color 0.3s ease, box-shadow 0.3s ease;
}

.btn-theme:hover {
  background-color: #0056b3;
  color: #ffffff;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
}

.btn-theme:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

/* General styling for the form container */
.form-container {
  display: flex;
  justify-content: space-between;
  margin-bottom: 1em;
}

/* Styling for the input filters */
.input-filters {
  display: flex;
  flex-wrap: wrap;
  gap: 1em;
}

/* Styling for each filter item */
.input-filters ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

/* Styling for each filter label */
.input-filters label {
  display: block;
  margin-bottom: 0.5em;
  font-weight: bold;
  color: #333;
}

/* Styling for each filter input */
.input-filters .input {
  width: 100%;
  padding: 0.5em;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
  transition: border-color 0.3s;
}

/* Change border color on focus */
.input-filters .input:focus {
  border-color: #007bff;
  outline: none;
}

.submit-button {
  display: block;
}

/* Change background color on hover */
.submit-button:hover {
  background-color: #0056b3;
}